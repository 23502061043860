import React, { useState } from "react";
import styled from 'styled-components';
import  close  from '../close-dialog.png';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const TeamLoginWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(#000, .8);
    z-index: 999;
    opacity: 1;
    transition: all .3s;
      
    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);
    }   

    .login_content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 0;
        height: 0;
        box-shadow: 0 2rem 4rem rgba(#000, .2);
        border-radius: 5px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(8, 1fr);
        overflow: hidden;
        position: relative;
        opacity: 1;
        border-top: 1rem solid rgba(#fff, .5);
        transition: all 1s;
        background-image: linear-gradient(rgba(255,255,255,.5),rgba(255,255,255,.8)), url(../brave_player2_bkgrd.png);
        background-blend-mode: lighten;
        background-size: cover;
        background-position: top;
       
    }

    .login {

        display: flex;
        align-items: center;
        justify-content: center;
        width: 30vw;
        height: 25vh;
        background-color: var(--color-grey-light-1);
        border-top: 5px var(--color-primary-light) solid;

        @media (max-width:750px) {
            width:60vw;
        }

        @media (max-width:400px) {
            width:90vw;
        }
    }

    .btn_close {
        width: 3rem;
        height: 3rem;
        position: absolute;
        top:5;
        left: 5;
        cursor: pointer;
        margin-top: .5rem;
    }

    .login_container {
        display: flex;
        flex-direction: column;
    }

    .code {
        width: 10rem;
        height: 2rem;
        font-family: "Raleway";
        margin-top: .5rem;
        border-radius: 5px;
        border: 2px solid var(--color-primary-light);

    }

    .login_btn {
        margin-top: .5rem;
        display: flex;
        align-self: center;
        justify-content: center;
        width: 9rem;
        padding: .6rem;
        background-color: var(--color-primary-light);
        cursor: pointer;
        border-radius: 5px;
    }

    .lbl {
       color: var(--color-primary-light);
    }
`;


const TeamLogin = (props) => {

    const notify = () => toast("Team Not Found Please Try Again.", {position: toast.POSITION.BOTTOM_CENTER});

 
    return(
        <TeamLoginWrapper>
            <div className="cnt">
             <img className="btn_close" src={close} alt="close" onClick={props.close} />
             <div className="login">
               
                <div className="login_container">
                    <div className="fld">
                        <div className="lbl">Login</div>
                        <div><input type="text" className="code" placeholder="Team Code"/></div>
                    </div>
                    <div onClick={notify} className="login_btn">Submit</div>
                </div>
            </div>
            <ToastContainer />
            </div>

        </TeamLoginWrapper>
    );
};

export default TeamLogin;