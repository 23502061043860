import React, { useState } from "react";
import styled from 'styled-components';
import Toast from './toaster';
import { isValidString, URL, getAPI } from '../constants';

const ContactRolldownWrapper = styled.div`
        width: 100vw;
        background-color: navy;
        height: 22vh;

    .contact-rolldown {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        

    }

    #email_container {
        width: 15rem;
        height: 2rem;
    }

    #team_container {
        width: 15rem;
        height: 2rem;
    }


    #sport_container {
        width: 15rem;
        height: 2rem;
    }
    .err_list {
        color: red;
        font-size: .8rem;
    }

    #contact_container {
        width: 15rem;
        height: 2rem;
    }

    .btn-hover-slide {
        border: 2px white solid;
        border-radius: 5px;
        font-family: 'BenchNine';
        padding: 3rem;
        margin-left:2rem;
        margin-right: 1rem;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        outline: none;
        overflow: hidden;
        position: relative;
        color: #fff;
        font-weight: 700;
        font-size: 1.1rem;
        background-color: #222;
        padding: 10px 50px;
        box-shadow: 0 5px 15px rgba(0,0,0,0.20);

        @media only screen and (max-width: 1090px) {
            padding: 5px 30px;
            font-size: .75rem;
            margin-left:1rem;
            margin-right: .5rem;
           
        }

        @media only screen and (max-width: 600px) {
            display:none;
            padding: 5px 30px;
           
        }


        & span {
            position: relative; 
            z-index: 1;
          }

          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 490%;
            width: 190%;
            background: var(--color-tertiary);
            -webkit-transition: all .5s ease-in-out;
            transition: all .5s ease-in-out;
            -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
            transform: translateX(-110%) translateY(-25%) rotate(45deg);
          }
          
          &:hover:after {
            -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
            transform: translateX(-9%) translateY(-25%) rotate(45deg);
          }
    }

    .gal {
        @media only screen and (max-width: 1000px) {
               display: none;
        }
    }

    .form_header-close {
    text-decoration: none;
    transition: all .3s;
    line-height: 1;
    margin-right: 2rem;
    cursor: pointer;
    fill: #fff;
    transition: fill .9s cubic-bezier(0.33, 1, 0.68, 1);

    &:hover {
        fill: red;
    }

    
}


`;

const ContactRolldown = (props) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [team, setTeam] = useState("");
    const [sport, setSport] = useState("")
    const [valid_err, setValErr] = useState([]);
    const [disable, setDisable] = useState(false);

    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");


    const setSubmit = () => {

       console.log("submit");
       setDisable(true);

        const errors = [];
         let obj = {};
   
         obj.name = isValidString(name) ? name.toUpperCase() : errors.push("Contact Name Is Required."); //*
         obj.email = isValidString(email) ? email : errors.push("Email Is Required."); //*
         obj.team_name = team;
         obj.sport = sport === 'Select' ? '' :  sport;
         
         console.log(obj);
   
        let json = JSON.stringify(obj);
        
 
        if (errors.length > 0) {
            setValErr(errors);
            setDisable(false);
            
        } else {

        const ul = `${URL}createNewContact`;
             //POST DATA
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-functions-key': getAPI() },
            body: json
            };
            fetch(ul, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setValErr([]);
                    setToastIcon("success");
                    setToastTitle("Success");
                    setToastMsg("Provider Saved Successfully");
                    setToastBackground("#5cb85c");
                    setToastState(true);

                    setDisable(false);

                })            
                .catch(err => {
                    setToastIcon("error");
                    setToastTitle("Error");
                    setToastBackground("#d9534f");
                    setToastMsg("There was a Problem Saving the Provider. Please Try Again.");
                    setToastState(true);
                    
                    setDisable(false);
                });
           
             
        }
    }

    const err = () => {
        try {
            if (Array.isArray(valid_err)) {
              return valid_err.map((item, index)=>{
                  return (
                        <ul>
                              <li className="err_list">{item}</li>
                             
                        </ul>
                   );
              });
          } else {
            return (
              <tr><td colSpan='8'><div>The Error List is Empty.</div></td></tr>
            );
          }
          } catch (err) {
            return;
          }
        }

    return(
        <ContactRolldownWrapper>
            <div className="contact-rolldown">
            <div id="error_list" >{err()}</div>
            <svg  onClick={props.close} className="form_header-close"  width="32" height="32"  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g><path d="M31.708 25.708c-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.105-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.048-0.252 0.124-0.357 0.228 0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.228-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.361 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357 0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.049 0.252-0.124 0.357-0.229 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.105 0.227 0.18 0.357 0.229 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.362-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357z"></path></g></svg>
                <div id="contact_container">
                    <input  value={name}  onChange={e => setName(e.target.value)} type="text" className="form_input req" placeholder="Contact Name" id="contact" maxLength="35" autoComplete="false"  />
                    <label htmlFor="contact" className="form_label">Contact Name*</label>
                </div>
                <div id="email_container">
                    <input  value={email}  onChange={e => setEmail(e.target.value)} type="text" className="form_input req" placeholder="Email" id="email" maxLength="35" autoComplete="false"  />
                    <label htmlFor="email" className="form_label">Email*</label>
                </div>
                <div id="team_container">
                    <input  value={team}  onChange={e => setTeam(e.target.value)} type="text" className="form_input req" placeholder="Team" id="team" maxLength="35" autoComplete="false"  />
                    <label htmlFor="team" className="form_label">Team / School</label>
                </div>
                <div id="sport_container">
                    <select id="sport" value={sport} className="form_input req" onChange={e => setSport(e.target.value)}>
                        <option value="Select">Select Sport</option>
                        <option value="Football">Football</option>
                        <option value="Soccer">Soccer</option>
                        <option value="Volleyball">Volleyball</option>
                        <option value="Basketball">Basketball</option>
                        <option value="Softball">Softball</option>
                        <option value="Baseball">Baseball</option>
                        <option value="Cheer">Cheer</option>
                        <option value="Soccer">Badminton</option>
                        <option value="Soccer">Gymnastics</option>
                        <option value="Soccer">Track & Field</option>
                        <option value="Soccer">Wrestling</option>
                        <option value="Other">Other</option>
                        
                    </select>
                    <label htmlFor="sport" className="form_label">Sport</label>
                </div>

                <div>
                {disable ? null : <button onClick={()=> setSubmit()} className="btn-hover-slide gal"><span>SUBMIT</span></button>}
                </div>

            </div>
            <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
            </div>

        </ContactRolldownWrapper>

    );

}

export default ContactRolldown;