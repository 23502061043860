import React, { useState } from "react";
import styled from 'styled-components';
import chaparral from '../teams/chaparral.png';
import greenway from '../teams/greenway.png';
import peoria from '../teams/peoria.png';
import az_sky from '../teams/az_sky.png';
import ignite from '../teams/ignite.png';
import liberty from '../teams/liberty.png';
import revolution from '../teams/revolution.png';
import willowcreek from '../teams/willowcreek.png';

const CustomersWrapper = styled.div`
        position: relative;
        display: flex;
        align-items: center;
        justify-content:space-around;
        flex-direction: row;
        border-top: 3px #000 solid;
        border-bottom: 3px #000 solid;
        width: 100vw;
        height:20vh;
        background-color: #fff;
        color: #fff;
        font-family: "Raleway";
        font-weight: bold;

        @media only screen and (max-width: 1300px) {
               display: none;
        }



    .cust-header {
        color:#fff;
        font-family: "BenchNine";
        font-size: 2rem;
        width: 15rem;
        height: 3rem;
        border: 2px navy solid;
        border-radius:10rem;
        background-color: navy;
    }

    .cust_text {
        text-align: center;
        margin-left: 2rem;
    }

    .cust-display {
        display: flex;

        width: 80vw;
        height: 18vh;
        border: 2px solid #000;
        border-radius: 10px;
        background-color: var(--color-grey-light-1);
    }

    @keyframes slideInLeft {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }

    .pos1 {

      position: absolute;
      top: 0;
      left: 50rem;
      animation: 3s ease-out 0s 1 slideInLeft;
       
    }

    //chaparral
    .pos-1 {

        position: absolute;
        top: 2rem;
        left: 58rem;
        animation: 3s ease-out .25s 1 slideInLeft;
        
        @media only screen and (max-width: 1700px) {
             left: 47rem;
        }
        @media only screen and (max-width: 1555px) {
             left: 40rem;
        }

        
    }

//greenway
        .pos-2 {

            position: absolute;
            top: 1rem;
            left: 77rem;
            width: 8.5rem;
            animation: 3s ease-out .5s 1 slideInLeft;

            @media only screen and (max-width: 1700px) {
             left: 67rem;
        }

            @media only screen and (max-width: 1555px) {
                left: 56rem;
            }
        
        }

//peoria
        .pos-3 {

        position: absolute;
        top: 2rem;
        left: 88rem;
        width: 10rem;
        animation: 3s ease-out .75s 1 slideInLeft;
        @media only screen and (max-width: 1700px) {
             left: 78rem;
        }
        @media only screen and (max-width: 1555px) {
              left: 64rem;
 }
        
        }

        //ignite

        .pos-4 {

        position: absolute;
        top: 1rem;
        left: 70rem;
        width: 6rem;
        animation: 3s ease-out 1s 1 slideInLeft;
        @media only screen and (max-width: 1700px) {
             left: 60rem;
        }

        @media only screen and (max-width: 1555px) {
              left: 50rem;
 }
        
        }
//revolution
        .pos-5 {

position: absolute;
top: 0;
left: 47rem;
width: 10rem;
animation: 3s ease-out 1s 1 slideInLeft;
@media only screen and (max-width: 1700px) {
             left: 37rem;
        }
@media only screen and (max-width: 1555px) {
              left: 30rem;
 }

}

//willow creek

.pos-6 {

position: absolute;
top: 1rem;
left: 30rem;
width: 8rem;
animation: 3s ease-out 1s 1 slideInLeft;

@media only screen and (max-width: 1700px) {
             left: 20rem;
        }

@media only screen and (max-width: 1555px) {
              left: 18rem;
 }

}

//liberty
.pos-7 {

position: absolute;
top: 2rem;
left: 40rem;
width: 7rem;
animation: 3s ease-out 1s 1 slideInLeft;

@media only screen and (max-width: 1700px) {
             left: 30rem;
        }

@media only screen and (max-width: 1555px) {
              left: 24rem;
 }

}

//az sky

.pos-8 {

position: absolute;
top: 2rem;
left: 100rem;
width: 6.5rem;
animation: 3s ease-out 1s 1 slideInLeft;

@media only screen and (max-width: 1700px) {
             left: 90rem;
        }

@media only screen and (max-width: 1555px) {
              left: 74rem;
 }

}

    .cust-1 {
        
        @media only screen and (max-width: 1550px) {
              transform: scale(.75);
             

        }
        
    }

    
`;


const Customers = () => {

    
    return(
        <CustomersWrapper>
            <div className="cust-header"><span className="cust_text">Teams that Get It!</span></div>
            <div className="cust-display">
                <img className="cust-1 pos-1" src={chaparral}  alt="chaparral"/>
                <img className="cust-1 pos-2" src={greenway}  alt="greenway"/>
                <img className="cust-1 pos-3" src={peoria}  alt="peoria"/>
                <img className="cust-1 pos-4" src={ignite}  alt="ignite"/>
                <img className="cust-1 pos-5" src={revolution}  alt="revolution"/>
                <img className="cust-1 pos-6" src={willowcreek}  alt="willowcreek"/>
                <img className="cust-1 pos-7" src={liberty}  alt="liberty"/>
                <img className="cust-1 pos-8" src={az_sky}  alt="az_sky"/>
                
            </div>
        </CustomersWrapper>
    );
};

export default Customers;