import React, { useState } from "react";
import styled from 'styled-components';
import  close  from '../close-dialog.png';
import Toast from './toaster';
import { isValidString, URL, getAPI } from '../constants';
import 'react-toastify/dist/ReactToastify.css';
import BarLoader from "react-spinners/BarLoader";
import { css } from "@emotion/react";

const ContactFormWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(#000, .8);
    z-index: 999;
    opacity: 1;
    transition: all .3s;
      
    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);
    }   

    .contact {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 30vw;
        height: 60vh;
        background-color:var(--color-grey-light-1);
        border-top: 5px solid var(--color-primary-light);

        @media (max-width:1200px) {
            width:60vw;
            height:75vh;
        }

        @media (max-width:655px) {
            width:60vw;
            height:75vh;
        }

        @media (max-width:400px) {
            width:90vw;
            height:75vh;
        }
    }

  
    .btn_close {
        width: 3rem;
        height: 3rem;
        position: absolute;
        top:5;
        left: 5;
        cursor: pointer;
        margin-top: .5rem;
    }

    .contact_btn {
        margin-top: 3rem;
        display: flex;
        align-self: center;
        justify-content: center;
        width: 9rem;
        padding: .6rem;
        background-color: var(--color-primary-light);
        cursor: pointer;
        border-radius: 5px;
    }

    .lbl {
       color: var(--color-primary-light);
    }

    .container {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }

    .fld {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;

        @media (max-width: 725px) {
           font-size:.9rem;

        }
    }

    .fld > input {
        width: 16rem;
        height: 2rem;
        font-family: "Raleway";
        margin-top: .5rem;
        border-radius: 5px;
        border: 2px solid var(--color-primary-light);

        @media (max-width: 1055px) {
            width:12rem;

        }

        @media (max-width: 725px) {
            width:9rem;

        }

    }

    .fld > label {
        font-family: "Raleway";
        color: var(--color-primary-light);
    }

    .line {
        border: var(--line);
        width: 100%;
        margin-top: 2rem;
    }

    .email_info {
        font-family: "Raleway";
        color: var(--color-primary-light);
        text-align: center;
        margin-top: .5rem;
    }

    .eml_reply {
        @media (max-width:1055px) {
            display:none;
        } 
    }

    
    #sport_container {
        width: 15rem;
        height: 2rem;
        margin-top: 3rem;
    }

    .err_list {
        color: red;
        font-size: .8rem;
    }


`;


const ContactForm = (props) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [team, setTeam] = useState("");
    const [sport, setSport] = useState("")
    const [valid_err, setValErr] = useState([]);
    const [disable, setDisable] = useState(false);

    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");

    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#ffffff");

    const [msg, setNotify] = useState("Info Submitted. Expect an email shortly")

    const notify = () => toast(msg, {position: toast.POSITION.BOTTOM_CENTER});

    const submit = () => {
        
        console.log("submit");
        setDisable(true);
 
         const errors = [];
          let obj = {};
    
          obj.name = isValidString(name) ? name.toUpperCase() : errors.push("Contact Name Is Required."); //*
          obj.email = isValidString(email) ? email : errors.push("Email Is Required."); //*
          obj.team_name = team;
          obj.sport = sport === 'Select' ? '' :  sport;
          
          console.log(obj);
    
         let json = JSON.stringify(obj);
         
  
         if (errors.length > 0) {
             setValErr(errors);
             setDisable(false);
             
         } else {
 
         const ul = `${URL}createNewContact`;
              //POST DATA
         const requestOptions = {
             method: 'POST',
             headers: { 'Content-Type': 'application/json', 'x-functions-key': getAPI() },
             body: json
             };
             fetch(ul, requestOptions)
                 .then(response => response.json())
                 .then(data => {
                     setValErr([]);
                     setToastIcon("success");
                     setToastTitle("Success");
                     setToastMsg("Provider Saved Successfully");
                     setToastBackground("#5cb85c");
                     setToastState(true);
 
                     setDisable(false);
 
                 })            
                 .catch(err => {
                     setToastIcon("error");
                     setToastTitle("Error");
                     setToastBackground("#d9534f");
                     setToastMsg("There was a Problem Saving the Provider. Please Try Again.");
                     setToastState(true);
                     
                     setDisable(false);
                 });
            
              
         }
       
    };


    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        padding: .5rem;
        background-color: #018dc0;
       
`;

const err = () => {
    try {
        if (Array.isArray(valid_err)) {
          return valid_err.map((item, index)=>{
              return (
                    <ul>
                          <li className="err_list">{item}</li>
                         
                    </ul>
               );
          });
      } else {
        return (
          <tr><td colSpan='8'><div>The Error List is Empty.</div></td></tr>
        );
      }
      } catch (err) {
        return;
      }
    }

    return(
        <ContactFormWrapper>
           
            <div className="frm">
            <img className="btn_close" src={close} alt="close" onClick={props.close} />
            <div className="contact">
               
                <div className="container">
                    <div id="error_list" >{err()}</div>
                    <div  className="fld">
                        <label>Contact Name*</label>
                        <input type="text" size="50" className="contact_name" onChange={(e) => setName(e.target.value) } />
                    </div>
                    <div  className="fld">
                        <label>Contact Email*</label>
                        <input type="text" size="110" className="contact_email" onChange={(e) => setEmail(e.target.value) } />
                    </div>
                 
                    <div  className="fld">
                        <label>Team Name / High School</label>
                        <input type="text" size="50" className="team_name" onChange={(e) => setTeam(e.target.value) } />
                    </div>
                    <div id="sport_container">
                    <select id="sport" value={sport} className="form_input req" onChange={e => setSport(e.target.value)}>
                        <option value="Select">Select Sport</option>
                        <option value="Football">Football</option>
                        <option value="Soccer">Soccer</option>
                        <option value="Volleyball">Volleyball</option>
                        <option value="Basketball">Basketball</option>
                        <option value="Softball">Softball</option>
                        <option value="Baseball">Baseball</option>
                        <option value="Cheer">Cheer</option>
                        <option value="Soccer">Badminton</option>
                        <option value="Soccer">Gymnastics</option>
                        <option value="Soccer">Track & Field</option>
                        <option value="Soccer">Wrestling</option>
                        <option value="Other">Other</option>
                        
                    </select>
                    <label htmlFor="sport" className="form_label">Sport</label>
                </div>


                    {disable ? null :<div onClick={submit} className="contact_btn">{loading ? <BarLoader color={color} loading={loading} css={override} size={150} /> : "Submit"}</div>}
                    
                    
                </div>
                <div className="line"></div>
                {/* <div className="email_info">
                    <span>Or just drops an email at <a href="mailto: info@blastbrands.net?subject=Ifo Please"> info@blastbrands.net</a> </span><br></br> <span className="eml_reply">and we will reply back with all you need.</span>
                </div> */}
            </div>
            </div>

            <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
            </div>
        </ContactFormWrapper>
    );
};

export default ContactForm;