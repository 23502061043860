import React from 'react';
import styled from 'styled-components';
import { imgUrl } from '../constants/';
import  close  from '../close-dialog.png';

const DetailWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 75vw;
    height: 60vh;
    background-color:transparent;
    border-top: 5px solid var(--color-primary-light);
    opacity: 1;
    z-index: 999;
    overflow-y: scroll;
  
    

    .btn_close {
        width: 3rem;
        height: 3rem;
        position: absolute;
        top:5;
        left: 5;
        cursor: pointer;
    }

   

    
`;

const BannerDetail = (props) => {
    return(
        <DetailWrapper onClick={props.close}>
                <img className="btn_close" src={close} alt="close" onClick={props.close} />
                <img className="banner" src={`${imgUrl}${props.which}`} alt="Banner" />
        </DetailWrapper>

    );
};

export default BannerDetail;