import React, { useState } from 'react';
import styled from 'styled-components';
import TeamLogin from './team-login';
import Gallery from './gallery';
import Menu from './menu';
import logo from '../blast_logo.png';


const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100vw;
    height:10vh;
    background-color: #000;
    color: #fff;
    font-family: "Raleway";
    font-weight: bold;

    @media only screen and (max-width: 1090px) {
        justify-content:center;
        }

    .logo {
       width: 15rem;
        margin-left: 1rem;
        @media only screen and (max-width: 1000px) {
              transform: scale(.65);
        }
    }

    .rocket {
        width: 7rem;
        margin-left: -2.75rem;
        margin-right: 13rem;
        @media only screen and (max-width: 1000px) {
              transform: scale(.6);
              margin-right: 0;
        }
        
    }
    .motto {
        margin: 0 auto;
        display:flex;
        
        justify-content: center;
        align-items: center;


        @media only screen and (max-width: 1000px) {
               display: none;
        }

    }

    .menu {
        display: none;

        @media only screen and (max-width: 290px) {
            display:none;
           
        }
    }

    .btn-hover-slide {
        border: 2px white solid;
        border-radius: 5px;
        font-family: 'BenchNine';
        padding: 3rem;
        margin-left:2rem;
        margin-right: 1rem;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        outline: none;
        overflow: hidden;
        position: relative;
        color: #fff;
        font-weight: 700;
        font-size: 1.1rem;
        background-color: #222;
        padding: 10px 50px;
        box-shadow: 0 5px 15px rgba(0,0,0,0.20);

        @media only screen and (max-width: 1090px) {
            padding: 5px 30px;
            font-size: .75rem;
            margin-left:1rem;
            margin-right: .5rem;
           
        }

        @media only screen and (max-width: 600px) {
            display:none;
            padding: 5px 30px;
           
        }


        & span {
            position: relative; 
            z-index: 1;
          }

          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 490%;
            width: 190%;
            background: var(--color-tertiary);
            -webkit-transition: all .5s ease-in-out;
            transition: all .5s ease-in-out;
            -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
            transform: translateX(-98%) translateY(-25%) rotate(45deg);
          }
          
          &:hover:after {
            -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
            transform: translateX(-9%) translateY(-25%) rotate(45deg);
          }
    }

    .gal {
        @media only screen and (max-width: 1000px) {
               display: none;
        }
    }

`;

const Header = () => {

    const [team, setTeam] = useState(false);
    const [gallery, setGallery] = useState(false);
    const [menu, setMenu] = useState(false);

    return(
        <HeaderWrapper>
                {menu ? <Menu/> : null}
                {gallery ? <Gallery close={() => setGallery(false)}/> : null}
                {team ? <TeamLogin close={() => setTeam(false)}/> : null}
                <img className="logo" src={logo} alt="blast" />
                <div className="motto">Branding That Gets You Noticed</div>
                <div className="patient__btn-bar">
                {/* <svg onClick={()=> setMenu(true)}  className="menu" fill="#ffffff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px"><path d="M 0 9 L 0 11 L 50 11 L 50 9 Z M 0 24 L 0 26 L 50 26 L 50 24 Z M 0 39 L 0 41 L 50 41 L 50 39 Z"/></svg> */}
                <button onClick={()=> setGallery(true)} className="btn-hover-slide gal"><span>Gallery</span></button>
                <button onClick={() => setTeam(true)} className="btn-hover-slide"><span>Team Login</span></button>
                        
                    
          </div>
           
        </HeaderWrapper>
    );

};

export default Header;