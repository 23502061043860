import React, { useState } from "react";
import styled from 'styled-components';

const FooterWrapper = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        border-top: 3px #000 solid;
        border-bottom: 3px #000 solid;
        width: 100vw;
        height:5vh;
        background-color: #000;
        color: #fff;
        font-family: "Raleway";
        font-weight: bold;

`;


const Footer = () => {

    
    return(
        <FooterWrapper>

        </FooterWrapper>
    );
};

export default Footer;