import React, { useState } from "react";
import styled from 'styled-components';




const ContactWrapper = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 100vw;
        height:7vh;
        background-color: var(--color-grey-light-1);
        color: #fff;
        font-family: "Raleway";
        font-weight: bold;

        .btn-hover-slide {
            border: 2px #000 solid;
            border-radius: 5px;
            font-family: 'BenchNine';
            margin-left:2rem;
            margin-right: 1rem;
            text-align: center;
            cursor: pointer;
            text-transform: uppercase;
            outline: none;
            overflow: hidden;
            position: relative;
            color: #000;
            font-weight: 700;
            font-size: 1.1rem;
            background-color: #fff;
            padding: 10px 50px;
            /* margin: 0 auto; */
            box-shadow: 0 5px 15px rgba(0,0,0,0.20);

            @media only screen and (max-width: 1090px) {
                padding: 7px 40px;
                margin-left:1rem;
                margin-right: 1rem;
            }

            & span {
                position: relative; 
                z-index: 1;
            }

            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 490%;
                width: 190%;
                background: var(--color-tertiary);
                -webkit-transition: all .5s ease-in-out;
                transition: all .5s ease-in-out;
                -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
                transform: translateX(-98%) translateY(-25%) rotate(45deg);
          }
          
          &:hover:after {
            -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
            transform: translateX(-9%) translateY(-25%) rotate(45deg);
          }
    }

    .arw {
        width: 3rem;

        
        @media only screen and (max-width: 400px) {
               display: none;
            }
    }
`;


const Contact = (props) => {

  


    return(
        <ContactWrapper>
                
                 
                  <button onClick={props.open} className="btn-hover-slide"><span>Get Started Now</span></button>
                
        </ContactWrapper>
    );
};

export default Contact;