import React from "react";
import styled from 'styled-components';


const ProductsWrapper = styled.div`
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: row;
        width: 100vw;
        height:20vh;
        background-color: #000;
        color: #fff;
        font-family: "Raleway";
        font-weight: bold;

        @media only screen and (max-width: 1000px) {
               flex-direction: column;
               height:60vh;
               justify-content: flex-start;
               align-items: center;
        }

        
        @media only screen and (max-width: 700px) {
             
               height:70vh;
               justify-content: flex-start;
               
        }

        @media only screen and (max-width: 540px) {
             
             height:80vh;
             
      }

      @media only screen and (max-width: 440px) {
             
             height:90vh;
             
      }

      @media only screen and (max-width: 370px) {
             
             height:120vh;
             
      }



        .prd-header {
            margin-top:1rem;
            width:30vw;
            display: flex;
            flex-direction:column;
            justify-content: center;
            align-items: center;

            @media only screen and (max-width: 1200px) {
                font-size: 1rem;
            }

            @media only screen and (max-width: 900px) {
                font-size: .75rem;
                margin-top: .5rem;
                width: 60vw;
                justify-content: flex-start;
            }
           

        }

        .prd-cat {

            width: 20vw;
            margin-top:.5rem;
            margin-left: -1rem;
            color: #000;
            display: flex;
            align-items: flex-start;
            justify-content: space-around;

        }

        .prd-div {
            
            
        }

        .player_banner {
            width: 7rem;
        }

        li {
            padding: .25rem;

            @media only screen and (max-width: 1200px) {
                font-size: .75rem;
            }
        }


`;


const Products = () => {

    
    return(
        <ProductsWrapper>
                
                <div className="prd-header">
                    <span>BANNERS</span>
                    <ul>
                        <li>Quality 13oz. Vinyl.</li>
                        <li>Grommets every two feet.</li>
                        <li>Environmently Friendly Latex Inks.</li>
                        <li>Large Selection of Sizes Available.</li>
                    </ul>

                </div>
                <div className="prd-header">
                    <span>POSTERS</span>
                    <ul>
                        <li>Photo Quality Printing.</li>
                        <li>Foam Backed.</li>
                        <li>Sizes up to 20" X 30" Avaialble.</li>
                        <li>Both Team and Player Options Available.</li>
                       
                    </ul>
                    
                </div>
                <div className="prd-header">
                    <span>MOTION GRAPHICS</span>
                    <ul>
                        <li>Bring your Brand to Life.</li>
                        <li>Graphics Designed for Social Media Platforms.</li>
                        <li>Special Effects and Transitions.</li>
                    </ul>
                    
                </div>
                
        </ProductsWrapper>
    );
};

export default Products;