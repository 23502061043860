import {useState} from 'react';
import Header from './components/header';
import Contact from './components/contact';
import Process from './components/process';
import Products from './components/products';
import Customers from './components/customers';
import ContactForm from './components/contact-form';
import Footer from './components/footer';
import Social from './components/social';
import styled from 'styled-components';
import ContactRolldown from './components/contact-rolldown';
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import useWindowSize from './hooks/useWindowSize';

const AppWrapper = styled.div`

display: flex;
align-items: flex-start;
justify-content: center;
width: 100vw;
height: 100vh;
background-color: #000;



`;




function App() {

  const [slide, setSlide] = useState(false);
  const [form, setContact] = useState(false);


  const {screenWidth, screenHeight} = useWindowSize();

 

  const openContact = () => {
    console.log(screenWidth, screenHeight);

    if(screenWidth < 1200) {
      setContact(true);
    } else {
      setSlide(true);

    }
    
  }

  return (
    <AppWrapper>
    <div className="App">
        <Social />
        <Header />
        <Contact open={() => openContact()} />
        { form ? <ContactForm  close={() => setContact(false)}/> : null }
        <SlideDown className={'my-dropdown-slidedown'}>
          {slide ? <ContactRolldown  close={() => setSlide(false)} /> : null}
        </SlideDown>
        <Process />
        <Products/>
        {/* <Customers/> */}
        <Footer />
       
     
    </div>

    </AppWrapper>
  );
}

export default App;
