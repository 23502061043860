import React, { useState } from "react";
import styled from 'styled-components';
import BannerDetail from './banner-detail';
import { imgUrl } from '../constants/';
import  close  from '../close-dialog.png';


const GalleryWrapper = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(#000, .8);
    z-index: 999;
    opacity: 1;
    transition: all .3s;
    border-radius: 25px;
    overflow: scroll;

    @media only screen and (max-width:1500px) {
        /* transform: scale(.75); */
    }
      
    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);
    }  
    
 .gallery-bkgrd {

    display: flex;
    flex-direction: column;
    width: 95vw;
    height: 85vh;
    background-color:#cdcdcd;
    border-top: 5px navy solid;
    opacity: .9;
    overflow: scroll;
    @media (max-width:1490px) {
            width:100vw;
    }
}

.gallery {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(9, 5vw);
    grid-gap: 1.5rem;
    padding: 1.5rem;

    @media only screen and (max-width:1500px) {
        grid-gap: 1rem;
        padding: 1rem;

        /* transform: scale(.85); */
    }
}

.gallery-item {
    &-1 {
       
        grid-row: 1 / span 2;
        grid-column: 1 / span 3;
    }

    &-2 {
       
        grid-row: 1 / span 2;
        grid-column: 4 / span 4;
    }
    &-3 {
        
       grid-row: 1 / span 2;
       grid-column: 8 / span 2;
    }

    &-4 {
       
        grid-row: 3 / span 2;
        grid-column: 1 / span 3;
    }

    &-5 {
       
       grid-row: 3 / span 3;
       grid-column: 5 / span 2;
   }

   &-6 {
       
       grid-row: 5 / span 2;
       grid-column: 1 / span 2;
   }

   &-7 {
       
       grid-row: 5 / span 2;
       grid-column: 5 / span 2;
   }

   &-8 {
       
       grid-row: 8 / span 2;
       grid-column: 1 / span 2;
   }

   &-9 {
       
       grid-row: 1 / span 2;
       grid-column: 10 / span 2;
   }
   &-10 {
       
       grid-row: 6 / span 2;
       grid-column: 1 / span 2;
   }
   &-11 {
       
       grid-row: 6 / span 2;
       grid-column: 5 / span 2;
   }
   &-12 {
       
       grid-row: 7 / span 2;
       grid-column: 1 / span 2;
   }
   &-13 {
       
       grid-row: 7 / span 2;
       grid-column: 5 / span 2;
   }
   &-14 {
       
       grid-row: 6 / span 2;
       grid-column: 10 / span 2;
   }

   &-15 {
       
       grid-row: 8 / span 2;
       grid-column: 5 / span 2;
   }

   &-16 {
       
       grid-row: 6 / span 2;
       grid-column: 8 / span 2;
   }
}

.gallery-header {
    
}

.gallery-banner {
       
        display: block;
        object-fit: cover;
        cursor: pointer;

        @media (max-width:1490px) {
            transform: scale(.9);
    }

    @media (max-width:1295px) {
            transform: scale(.7);
    }

    @media (max-width:1000px) {
            transform: scale(.5);
    }
}

.img1 {
    width: 21rem;
}

.img2{
    width: 29rem;
   
}

.img3 {
   
    width: 15rem;
    height: 22rem;
}

.img4 {
    width: 27rem;
    height: 10rem;
}

.img5 {
    margin-top: -1.5rem;
    width: 20rem;
}

.img6 {
    width: 23rem;
}

.img7 {
    margin-top: 1rem;
    margin-left: -2rem;
    width: 24rem;
}

.img8 {
    margin-left: 0;
    margin-top: 15rem;
    width: 25rem;
}

.img9 {
    margin-left: 2rem;
    margin-top: .7rem;
    width: 16rem;
}


.img10 {
    margin-left: 0;
    margin-top:3rem;
    width: 24rem;
}


.img11 {
    margin-left: -1rem;
    margin-top: 3rem;
    width: 24rem;
}


.img12 {
    margin-left: 0;
    margin-top: 9rem;
    width: 24rem;
}


.img13 {
    margin-left: -1rem;
    margin-top: 9rem;
    width: 24rem;
}



.img14 {
    margin-left: 2rem;
    margin-top: .7rem;
    width: 16rem;
}

.img15 {
    margin-left: 0;
    margin-top: 15rem;
    width: 24rem;
}

.img16 {
    margin-left: 2rem;
    margin-top: .7rem;
    width: 16rem;
}


.btn_close {
        width: 3rem;
        height: 3rem;
        position: absolute;
        top:5;
        left: 5;
        cursor: pointer;
    }

.gallery-container {
    position: absolute;
    
}
`;


const Gallery = (props) => {

    const [dtl, setDetail] = useState(false);
    const [which, setWhich] = useState('banner-1.jpg');

    return(
        
        <GalleryWrapper>
            
             <div className="gallery-bkgrd">
            
             <div className="gallery-header">
             <img className="btn_close" src={close} alt="close" onClick={props.close} />
            </div>
                 <div className="gallery">
                     <figure className="gallery-item gallery-item-1" onClick={() => {setWhich('banner-1.jpg'); setDetail(true); }}>
                       <img src={`${imgUrl}banner-1.jpg`} alt="Gallery Banner 1" className="gallery-banner img1"/>
                     </figure>
                     <figure className="gallery-item gallery-item-2" onClick={() => {setWhich('banner-2.jpg'); setDetail(true); }}>
                       <img src={`${imgUrl}banner-2.jpg`} alt="Gallery Banner 2" className="gallery-banner img2"/>
                     </figure>
                     <figure className="gallery-item gallery-item-3" onClick={() => {setWhich('poster-1.jpg'); setDetail(true); }}>
                       <img src={`${imgUrl}poster-1.jpg`} alt="Gallery Banner 3" className="gallery-banner img3"/>
                     </figure>

                     <figure className="gallery-item gallery-item-4" onClick={() => {setWhich('banner-4.jpg'); setDetail(true); }}>
                       <img src={`${imgUrl}banner-4.jpg`} alt="Gallery Banner 4" className="gallery-banner img4"/>
                     </figure>
                     <figure className="gallery-item gallery-item-5" onClick={() => {setWhich('banner-5.jpg'); setDetail(true); }}>
                       <img src={`${imgUrl}banner-5.jpg`} alt="Gallery Banner 5" className="gallery-banner img5"/>
                     </figure>

                     <figure className="gallery-item gallery-item-6" onClick={() => {setWhich('banner-6.jpg'); setDetail(true); }}>
                       <img src={`${imgUrl}banner-6.jpg`} alt="Gallery Banner 6" className="gallery-banner img6"/>
                     </figure>

                     <figure className="gallery-item gallery-item-7" onClick={() =>{setWhich('banner-9.jpg'); setDetail(true); }}>
                       <img src={`${imgUrl}banner-9.jpg`} alt="Gallery Banner 7" className="gallery-banner img7"/>
                     </figure>

                     <figure className="gallery-item gallery-item-8" onClick={() => {setWhich('banner-3.jpg'); setDetail(true); }}>
                       <img src={`${imgUrl}banner-3.jpg`} alt="Gallery Banner 8" className="gallery-banner img8"/>
                     </figure>

                     <figure className="gallery-item gallery-item-9" onClick={() => {setWhich('poster-6.jpg'); setDetail(true); }}>
                       <img src={`${imgUrl}poster-6.jpg`} alt="Gallery Banner 9" className="gallery-banner img9"/>
                     </figure>
                     <figure className="gallery-item gallery-item-10" onClick={() => {setWhich('banner-11.jpg'); setDetail(true); }}>
                       <img src={`${imgUrl}banner-11.jpg`} alt="Gallery Banner 10" className="gallery-banner img10"/>
                     </figure>
                     <figure className="gallery-item gallery-item-11" onClick={() => {setWhich('banner-12.jpg'); setDetail(true); }}>
                       <img src={`${imgUrl}banner-12.jpg`} alt="Gallery Banner 11" className="gallery-banner img11"/>
                     </figure>
                     <figure className="gallery-item gallery-item-12" onClick={() => {setWhich('banner-13.jpg'); setDetail(true); }}>
                       <img src={`${imgUrl}banner-13.jpg`} alt="Gallery Banner 12" className="gallery-banner img12"/>
                     </figure>
                     <figure className="gallery-item gallery-item-13" onClick={() => {setWhich('banner-14.jpg'); setDetail(true); }}>
                       <img src={`${imgUrl}banner-14.jpg`} alt="Gallery Banner 13" className="gallery-banner img13"/>
                     </figure>

                     <figure className="gallery-item gallery-item-14" onClick={() => {setWhich('poster-9.jpg'); setDetail(true); }}>
                       <img src={`${imgUrl}poster-9.jpg`} alt="Gallery Banner 14" className="gallery-banner img14"/>
                     </figure>

                     <figure className="gallery-item gallery-item-15" onClick={() => {setWhich('banner-17.jpg'); setDetail(true); }}>
                       <img src={`${imgUrl}banner-17.jpg`} alt="Gallery Banner 15" className="gallery-banner img15"/>
                     </figure>  

                      <figure className="gallery-item gallery-item-16" onClick={() => {setWhich('poster-10.jpg'); setDetail(true); }}>
                       <img src={`${imgUrl}poster-10.jpg`} alt="Gallery Banner 16" className="gallery-banner img16"/>
                     </figure>               
                 
                 </div>
               
            </div>
            <div className="gallery-container">
            {dtl ? <BannerDetail which={which} close={() => setDetail(false)}/> : null}
            </div>
           
        </GalleryWrapper>
    );
};

export default Gallery;