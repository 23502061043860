import React, { useEffect } from 'react';
import styled from 'styled-components';
import checkIcon from '../assets/check.svg';
import errorIcon from '../assets/error.svg';
import infoIcon from '../assets/info.svg';
import warningIcon from '../assets/warning.svg'; 

const ToastWrapper = styled.div`

.notification-container {
    font-size: 14px;
    box-sizing: border-box;
    position: fixed;
    z-index: 999999;
}

    

.top-right {
    top: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
}

.bottom-right {
    bottom: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
}

.top-left {
    top: 12px;
    left: 12px;
    transition: transform .6s ease-in;
    animation: toast-in-left .7s;
}

.bottom-left {
    bottom: 12px;
    left: 12px;
    transition: transform .6s ease-in;
    animation: toast-in-left .7s;
}

.bottom-center {
    bottom: 0px;
    left:50%;
    margin-left:-30rem;
    transition: transform .6s ease-in-out;
    animation:  toast-in-bottom .7s;
}

.top-center {

}

.notification {
	background: #fff;
	transition: .3s ease;
	position: relative;
	pointer-events: auto;
	overflow: hidden;
	margin: 0 0 6px;
	padding: 30px;
	margin-bottom: 15px;
	width: 300px;
	max-height: 100px;
	border-radius: 3px 3px 3px 3px;
	box-shadow: 0 0 10px #999;
	color: #000;
	opacity: .9;
	background-position: 15px;
	background-repeat: no-repeat;
}

.notification:hover {
	box-shadow: 0 0 12px #fff;
	opacity: 1;
	cursor: pointer
}

.notification-title {
	font-weight: 700;
	font-size: 16px;
	text-align: left;
	margin-top: 0;
	margin-bottom: 6px;
    width: 300px;
    height: 18px;
}

.notification-message {
	margin: 0;
	text-align: left;
    height: 18px;
    margin-left: -1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 700;
	font-size: 16px;
}

.notification-image {
    float: left;
    margin-right: 15px;
}

.notification-image img {
    width: 30px;
    height: 30px;
}

.toast {
    height: 80px;
    width: 365px;
	color: #fff;
	padding: 20px 15px 10px 10px;
}


@keyframes toast-in-bottom {
	from {
	  transform: translateY(100%);
	  
	}
	to {
	  transform: translateY(0);
	}
}


@keyframes toast-in-right {
	from {
	  transform: translateX(100%);
	  
	}
	to {
	  transform: translateX(0);
	}
}

@keyframes toast-in-left {
	from {
		transform: translateX(-100%);
		
	}
	to {
		transform: translateX(0);
	}
}


.hide {
    animation: fadeout .7s;
    visibility: hidden;
   
}

@-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;} 
    to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes expand {
    from {min-width: 50px} 
    to {min-width: 350px}
}

@keyframes expand {
    from {min-width: 50px}
    to {min-width: 350px}
}
@-webkit-keyframes stay {
    from {min-width: 350px} 
    to {min-width: 350px}
}

@keyframes stay {
    from {min-width: 350px}
    to {min-width: 350px}
}
@-webkit-keyframes shrink {
    from {min-width: 350px;} 
    to {min-width: 50px;}
}

@keyframes shrink {
    from {min-width: 350px;} 
    to {min-width: 50px;}
}

@-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;} 
    to {bottom: 0px; opacity: 0;}
}

@keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
}

`;


const Toast = (props)  => {

  

     useEffect(() => {
        const interval = setInterval(() => {
           
                deleteToast();
           
        }, 3000);
        
        return () => {
            clearInterval(interval);
        }
    }, []);

   const setIcon = () => {

   
        switch(props.icon) {
            case "success":
                return checkIcon;
            case "info":
                return infoIcon;
            case "warning":
                return warningIcon;
            case "error":
                return errorIcon;
            default:
                return null;
        }
    }

    const deleteToast = () => {
        const toast = document.getElementById("toast_base");
        toast.classList.add("hide");
        props.close();
    }

   

    return (
        <ToastWrapper>
             <div id="toast_base" className={`notification-container ${props.position}`}>
                {
                    
                  
                        <div 
                            className={`notification toast ${props.position}`}
                            style={{ backgroundColor: props.backgroundColor }}
                        >
                           
                            <div className="notification-image">
                                <img src={setIcon()} alt="" />
                            </div>
                            <div>
                                <p className="notification-title">{props.title}</p>
                                <p className="notification-message">
                                    {props.description}
                                </p>
                            </div>
                        </div>
                   
                }
            </div>
        </ToastWrapper>
    );
}

export default Toast;
