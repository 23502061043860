export const imgUrl = 'https://blastsite.blob.core.windows.net/images/gallery/';
export const URL = 'https://blast-app-api.azurewebsites.net/api/';

const api = 'Oz9BwaG2nhz-AxzE0F6YYrmIW4cCkkBwxQYMOUP-GQKYAzFuovyrng==';


export const getAPI = () => {
    return api;
}

export const isValidString = (x) => {
    let fn = false;
    try {
        fn =  (x !== '') && (x !== undefined);
    } catch (err) {
       fn = false;
    }

   return fn;
};


