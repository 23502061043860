import React, { useState } from "react";
import styled from 'styled-components';
import arrow from '../arrow.png';
import photoshoot from '../photoshoot.png';
import design from '../design.png';
import print from '../print.png';
import photo from '../photo.png';
import create from '../create.png';
import develop from '../develop.png';

const ProcessWrapper = styled.div`
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: row;
        border-top: 3px #000 solid;
        border-bottom: 3px #000 solid;
        width: 100vw;
        height:35vh;
        background-color: #fff;
        color: #000;
        font-family: "Raleway";
        font-weight: bold;

        @media only screen and (max-width: 1000px) {
               display: none;
        }

        .capsule {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 10rem;
            /* height: 8rem; */
           
            padding: 1rem;
            
            @media only screen and (max-width: 1160px) {
                width: 9rem;
                height: 8rem;
                padding: 1rem;
            }
        }

        .arrow {
            width: 10rem;
            @media only screen and (max-width: 1160px) {
                width: 6rem;
                
            }

            
        }

        .pos {
            
            width: 3rem;
            height: 3rem;
            border-radius: 50px;
            background-color: navy;
            color: white;
            margin-left: -7rem;
            margin-top: -3rem;
            font-family: "Raleway";
            font-size: 2rem;
        }

        .pos span {
            margin-left: 1rem;
            text-align: center;
        }

        .desc {
            text-align: center;
            font-size: .86rem;
            width: 30rem;

           
            @media only screen and (max-width: 1500px) {
                font-size: .6rem;
                
            }
            
        }

        .img_container {
            text-align: center;
        }

       

`;


const Process = () => {

    
    return(
        <ProcessWrapper>
                <div className="capsule">
                     <div className="img_container"><img src={photo} alt="photoshoot"/></div>
                     <div className="desc">Schedule a Professional On-Site Photoshoot.</div>
                </div>
                <img className="arrow" src={arrow} alt="arrow" />
                    <div className="capsule">
                        <div className="img_container"><img src={create} alt="design"/></div>
                        <div className="desc">Work with our Designers to achieve the Perfect Brand for your Team.</div>
                </div>
                    <img className="arrow" src={arrow} alt="arrow" />
                    <div className="capsule">
                        <div className="img_container"><img src={develop} alt="print"/></div>
                        <div className="desc">Browse within your Exclusive Team Store. </div>
                </div>
        </ProcessWrapper>
    );
};

export default Process;